/* import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    todoReducers,
  //form: reduxFormReducer,	
	
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
 */

import { configureStore } from "@reduxjs/toolkit";
/* import PostsReducer from "./reducers/PostsReducer";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers"; */
import CreditCardReducer from "./reducers/CreditCardReducer";
import FXRateReducer from "./reducers/FXRateReducer";
import LeadReducer from "./reducers/LeadReducer";
import LoanReducer from "./reducers/LoanReducer";
import sideComparison from "./reducers/SideComparisonReducer";
import companySlice from "./reducers/CompanyReducer";
export const store = configureStore({
  reducer: {
    cards: CreditCardReducer,
    fxrates: FXRateReducer,
    leads: LeadReducer,
    loans: LoanReducer,
    comparer: sideComparison,
    company: companySlice,
  },
});
