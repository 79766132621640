import React from "react";
import MobileTable from "./MobileTable";
import DesktopTable from "./DesktopTable";
import "./index.css";
const SideComparison = ({ product, fields }) => {
  return (
    <div className="col-12">
      <h3 className="text-center">Compare Now</h3>
      <p className="text-center">
        Explore your options! Use the dropdown menus below to change the
        products being compared and find the perfect match for your needs.
      </p>
      <div className="card">
        <div className="card-body">
          <MobileTable product={product} comparisonFields={fields} />
          <DesktopTable product={product} comparisonFields={fields} />
        </div>
      </div>
    </div>
  );
};
export default SideComparison;
