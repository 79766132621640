import { createSlice } from "@reduxjs/toolkit";
import { getLoans } from "../../services/LoanService";

const initialState = {
  loan: {
    loanTerm: { value: "5", label: "5 Years" },
    vehYear: { value: "2024", label: "2024" },
    loanAmt: 1000000,
  },
  productType: "",
  cardList: [],
  cardsToCompare: [],
  cardsLoading: false,
  errors: {},
};

export const LoanSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [getLoans.fulfilled]: (state, { payload }) => {
      state.cardList = payload;
      state.cardsLoading = false;
    },
    [getLoans.pending]: (state) => {
      state.cardsLoading = true;
    },
    [getLoans.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.cardsLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = LoanSlice.actions;

export default LoanSlice.reducer;
