import { useParams } from "react-router-dom";

const UserStatus = () => {
  const params = useParams();
  return (
    <div>
      <h2>
        {params.status.split("-").pop() === "verified"
          ? "Thank you for verifying your account. You will be contacted as soon as we send your details over to the bank."
          : "We were unable to verify your email. You may need to sign up for the product again."}
      </h2>
      <h5>
        You can checkout our other products to see what you might be interested
        in.
      </h5>
    </div>
  );
};
export default UserStatus;
