// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-table {
  border:  1px solid rgba(238, 238, 238, 0.933);
  color: #a098ae;
  margin-bottom: 0.25rem;
}

.mobile-table-row {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.mobile-table-td {
  width: 50%;
  padding: 0.9375rem 0.625rem;
  border: 1px solid #eeee;
  min-height: 40px;
  text-align: center;
}

.mobile-table-td > span {
  display: block;
  color: black;
}

.desktop-table {
  border: 1px solid rgba(238, 238, 238, 0.933);
  width: 100% !important;
}
.dt-row {
  display: flex;
  align-items: stretch;
  min-height: 45px;
}
.dt-header{
  width: 15%;
  padding: 0.9375rem 0.625rem;
  color: black;
  border:  1px solid rgba(238, 238, 238, 0.933);
}

.dt-td {
  text-align: center;
  color: black;
  width: 28.333%;
  padding: 0.9375rem 0.625rem;
  border:  1px solid rgba(238, 238, 238, 0.933);
}

@media (min-width: 320px) {
  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }
}

@media (min-width: 1024px) {
  .desktop-table {
    display: table;
  }

  .mobile-table {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/jsx/components/SideComparison/index.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,4CAA4C;EAC5C,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,2BAA2B;EAC3B,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,2BAA2B;EAC3B,6CAA6C;AAC/C;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".mobile-table {\n  border:  1px solid rgba(238, 238, 238, 0.933);\n  color: #a098ae;\n  margin-bottom: 0.25rem;\n}\n\n.mobile-table-row {\n  display: flex;\n  width: 100%;\n  align-items: stretch;\n}\n\n.mobile-table-td {\n  width: 50%;\n  padding: 0.9375rem 0.625rem;\n  border: 1px solid #eeee;\n  min-height: 40px;\n  text-align: center;\n}\n\n.mobile-table-td > span {\n  display: block;\n  color: black;\n}\n\n.desktop-table {\n  border: 1px solid rgba(238, 238, 238, 0.933);\n  width: 100% !important;\n}\n.dt-row {\n  display: flex;\n  align-items: stretch;\n  min-height: 45px;\n}\n.dt-header{\n  width: 15%;\n  padding: 0.9375rem 0.625rem;\n  color: black;\n  border:  1px solid rgba(238, 238, 238, 0.933);\n}\n\n.dt-td {\n  text-align: center;\n  color: black;\n  width: 28.333%;\n  padding: 0.9375rem 0.625rem;\n  border:  1px solid rgba(238, 238, 238, 0.933);\n}\n\n@media (min-width: 320px) {\n  .mobile-table {\n    display: block;\n  }\n\n  .desktop-table {\n    display: none;\n  }\n}\n\n@media (min-width: 1024px) {\n  .desktop-table {\n    display: table;\n  }\n\n  .mobile-table {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
