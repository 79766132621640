import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configs from "../api-config";
export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (companyType, thunkAPI) => {
    try {
      let response = await axios.get(
        `${configs.baseUrl}/company?perPage=100&pageNo=1`
      );
      if (response.status === 200)
        return companyType === "investments"
          ? response.data.filter((x) =>
              x.description.toLowerCase().includes("investment")
            )
          : response.data.filter((x) =>
              x.description.toLowerCase().includes("commercial bank")
            );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
