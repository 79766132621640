import { createSlice } from "@reduxjs/toolkit";
import { getCompanies } from "../../services/CompanyService";

const initialState = {
  companyList: [],
  currBank: { _id: "1" },
  companyLoading: false,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        return {
          ...state,
          companyList: payload,
          companyLoading: false,
        };
      })
      .addCase(getCompanies.pending, (state) => {
        return {
          ...state,
          companyLoading: true,
        };
      })
      .addCase(getCompanies.rejected, (state, { payload }) => {
        return {
          ...state,
          companyLoading: false,
          companyList: [],
          errors: payload,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setState } = companySlice.actions;

export default companySlice.reducer;
