import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LeadForm from "./LeadForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { splitQueryString } from "../../../util";
import swal from "sweetalert";
const LeadModal = ({ item }) => {
  const [basicModal, setBasicModal] = useState(false);
  const dispatch = useDispatch();
  const { lead } = useSelector((state) => state.leads);
  const { search } = useLocation();
  const onSave = async (e) => {
    e.preventDefault();
    let leadService = null;
    let result = null;
    const newLead = {
      ...lead,
      productId: item._id,
      companyId: item.companyId,
      refferrer: search ? splitQueryString(search)?.appName : "Ratehogs",
    };
    try {
      leadService = await import("../../../services/LeadService");
      if (leadService) {
        result = await dispatch(leadService.createLead(newLead)).unwrap();
        if (result) {
          swal(
            "Details Received!",
            result.message ??
              "Form submitted. An officer from the bank will reach out to you soon.",
            "success"
          ).then(() => onClose());
        }
      }
    } catch (error) {
      swal("Oops", error?.message ?? "Something went wrong!", "error");
    }
  };
  const onClose = () => {
    setBasicModal(!basicModal);
    import("../../../store/reducers/LeadReducer").then((lr) => {
      dispatch(lr.setLead({ lead: {}, message: "", errors: {} }));
    });
  };
  return (
    <>
      {/* <!-- Button trigger modal --> */}
      <Button
        className={!item && "disabled"}
        variant="primary"
        onClick={() => setBasicModal(true)}
      >
        Request Callback <i className="fa fa-caret-right"></i>
      </Button>
      <div className="bootstrap-modal">
        {/* <!-- Modal --> */}
        <Modal className="fade" show={basicModal}>
          <Modal.Header>
            <Modal.Title>Personal Information</Modal.Title>
            <Button variant="" className="btn-close" onClick={onClose}></Button>
          </Modal.Header>
          <Modal.Body>
            <LeadForm />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="danger light">
              Close
            </Button>
            <Button onClick={onSave} variant="primary">
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/*  <ToastContainer /> */}
    </>
  );
};

export default LeadModal;
