import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
const CompanyBanner = React.lazy(() => import("../CompanyMenu"));
const SingleCard = React.lazy(() => import("./SingleCard"));

const CompareAuto = () => {
  const dispatch = useDispatch();
  const { cardList, cardsLoading } = useSelector((state) => state.loans);
  const { currBank } = useSelector((state) => state.company);
  const [activeGenre, setActiveGenre] = useState(1);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    import("../../../services/LoanService")
      .then((cs) =>
        dispatch(
          cs.getLoans({ companyId: currBank._id, productType: "autoloan" })
        )
      )
      .catch((err) => console.log(err));
  }, [dispatch, currBank._id]);

  useEffect(() => {
    const tempList = [...cardList];
    if (activeGenre === 1) {
      const tempList1 = tempList.filter(
        (x) => !Number.isNaN(parseFloat(x.maxLoanAmt))
      );
      tempList1.sort(
        (a, b) => parseFloat(a.maxLoanAmt) - parseFloat(b.maxLoanAmt)
      );
      const tempList2 = tempList.filter((x) =>
        Number.isNaN(parseFloat(x.maxLoanAmt))
      );
      const tempList3 = [...tempList1, ...tempList2];
      return setFiltered(tempList3);
    }

    if (activeGenre === 2) {
      const tempList1 = tempList.filter(
        (x) => !Number.isNaN(parseFloat(x.intRate))
      );
      tempList1.sort((a, b) => parseFloat(a.intRate) - parseFloat(b.intRate));
      const tempList2 = tempList.filter((x) =>
        Number.isNaN(parseFloat(x.intRate))
      );
      const tempList3 = [...tempList1, ...tempList2];
      return setFiltered(tempList3);
    }

    if (activeGenre === 3) {
      const tempList1 = tempList.filter(
        (x) => !Number.isNaN(parseFloat(x.maxPcntFin))
      );
      tempList1.sort(
        (a, b) => parseFloat(a.maxPcntFin) - parseFloat(b.maxPcntFin)
      );
      const tempList2 = tempList.filter((x) =>
        Number.isNaN(parseFloat(x.maxPcntFin))
      );
      const tempList3 = [...tempList1, ...tempList2];
      return setFiltered(tempList3);
    }
  }, [activeGenre, cardList]);

  return (
    <>
      <div className="row">
        <CompanyBanner link={"/auto-loans"} productType={"autoloan"} />
        {cardsLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !cardList.length ? (
          <h1>No auto loans found.</h1>
        ) : (
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-4">
                <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter=".rated" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={() => setActiveGenre(1)}
                          checked={activeGenre === 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Max. Loan Amount
                        </label>
                      </li>
                      <li data-filter=".notrated" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          onChange={() => setActiveGenre(2)}
                          checked={activeGenre === 2}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Interest Rate
                        </label>
                      </li>
                      <li data-filter=".spo" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          onChange={() => setActiveGenre(3)}
                          checked={activeGenre === 3}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Max. percentage financed
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-10">
                <ul
                  //layout
                  id="masonry"
                  className="row"
                  //transition={{ duration: 0.3 }}
                >
                  <AnimatePresence>
                    {filtered.map((item, index) => {
                      return (
                        <motion.li
                          layout
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="col-lg-12 col-md-6 infra rated px-3"
                          key={index}
                          //transition={{ duration: 0.5 }}
                        >
                          <SingleCard item={item} />
                        </motion.li>
                      );
                    })}
                  </AnimatePresence>
                </ul>
                {/* <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                // id="application-tbl1_paginate"
              >
                <Link className="paginate_button previous " to="/credit-cards">
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  {pagination.map((number, i) => (
                    <Link
                      key={i}
                      to="/credit-cards"
                       className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                        } `}
                    >
                      {number}
                    </Link>
                  ))}
                </span>

                <Link className="paginate_button next" to="/credit-cards">
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CompareAuto;
