import React from "react";
/* import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import Select from "react-select"; */
import LoanPage from "./LoanPage";

/* const init = false;
const reducer = (state, action) => {
  if (action.type === "reviewModal") {
    return { ...state, reviewModal: !state.reviewModal };
  }
  return state;
};

// Function to create an array of the last 7 years
const getLast7Years = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 8 }, (_, index) => ({
    value: currentYear - index,
    label: (currentYear - index).toString(),
  }));
}; */

// Utility function to format numbers with commas
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

/* const years = getLast7Years();

const loans = [
  {
    name: "NCB Commercial New Motor Vehicle Loan",
    maxTerm: 10,
    maxAge: 1,
    rate: 0.0965,
    fees: 8200,
    image:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730197581/ratehogs/Standardized/NCB_PNG_Standardized_cosycc.png",
  },
  {
    name: "JN Auto Loan - Used Vehicles",
    maxTerm: 5,
    maxAge: 7,
    rate: 0.11,
    fees: 6500,
    image:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730197581/ratehogs/Standardized/JN_PNG_Standardized_kuv93i.png",
  },
  {
    name: "CIBC Vehicle Loans - Used Vehicle",
    maxTerm: 5,
    maxAge: 5,
    rate: 0.12,
    fees: 9750,
    image:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730198402/ratehogs/Standardized/CIBC_PNG_Standardized_clfrgc.png",
  },
  {
    name: "Scotia Bank Auto Loan - New Vehicle",
    maxTerm: 10,
    maxAge: 1,
    rate: 0.085,
    fees: 5610,
    image:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730199382/ratehogs/Standardized/Scotia_PNG_Standardized_tndshc.png",
  },
];

const loanTerms = [
  { value: "1", label: "1 year" },
  { value: "2", label: "2 years" },
  { value: "3", label: "3 years" },
  { value: "4", label: "4 years" },
  { value: "5", label: "5 years" },
  { value: "6", label: "6 years" },
  { value: "7", label: "7 years" },
  { value: "8", label: "8 years" },
  { value: "9", label: "9 years" },
  { value: "10", label: "10 years" },
];
const options5 = [
  { value: "1", label: "Competition 1" },
  { value: "2", label: "Competition 2" },
  { value: "3", label: "Competition 3" },
];
 */
const LoanCalculator = () => {
  /*   const [loanOptions, setLoanOptions] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [interestRate, setInterestRate] = useState("");
  const [loanAmount, setLoanAmount] = useState(10000);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [loanTerm, setLoanTerm] = useState(0);
  const [selectedLoanTerm, setSelectedLoanTerm] = useState(null);
  const [state, dispatch] = useReducer(reducer, init);
  const [loanCards, SetLoanCards] = useState([]);
  const [carYear, setCarYear] = useState(0);
  const [selectedYear, setSelectedYear] = useState(null);
  const [resultsCount, setResultsCount] = useState(0);
 */
  /* useEffect(() => {
    // Generate loan options based on the loans array
    const generatedLoanOptions = loans.map((loan, index) => ({
      label: loan.name,
      value: index.toString(),
    }));
    setLoanOptions(generatedLoanOptions);

    //const carYears = getLast7Years();
    setSelectedYear(years[0]);
    setCarYear(years[0].value);

    // Set the default selected loan and its rate if desired
    setSelectedLoan(generatedLoanOptions[0]);
    setInterestRate(loans[0].rate);
    const defaultTerm = loanTerms[0];
    setSelectedLoanTerm(defaultTerm);
    setLoanTerm(defaultTerm.value);
    calculateLoanCards(loanAmount, defaultTerm.value, years[0].value);
  }, []);

  const handleLoanTermChange = (selectedOption) => {
    const newTerm = selectedOption.value;
    setSelectedLoanTerm(selectedOption);
    setLoanTerm(newTerm);
    calculateLoanCards(loanAmount, newTerm, carYear);
  };

  const handleLoanAmountChange = (event) => {
    const amount = parseFloat(event.target.value);
    console.log(amount);
    setLoanAmount(amount);
    calculateLoanCards(amount, loanTerm, carYear);
  };

  const handleYearChange = (selectedOption) => {
    const newYear = selectedOption.value;
    setSelectedYear(selectedOption);
    setCarYear(newYear);
    calculateLoanCards(loanAmount, loanTerm, newYear);
  }; */

  const calculateMonthlyPayment = (loanAmount, interestRate, loanTerm) => {
    const principal = parseFloat(loanAmount);
    const monthlyInterestRate = parseFloat(interestRate) / 12;
    const numberOfPayments = parseFloat(loanTerm) * 12;

    if (monthlyInterestRate === 0) {
      return principal / numberOfPayments;
    }

    return (
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
    );
  };

  /*   const calculateLoanCards = (loanAmount, loanTerm, carYear) => {
    console.log(loanAmount);
    const amountCheck = Number.isNaN(loanAmount) ? 0 : loanAmount;
    const currentYear = new Date().getFullYear();
    let filteredLoans = loans;

    filteredLoans = filteredLoans.filter(
      (loan) => currentYear - loan.maxAge <= carYear
    );
    filteredLoans = filteredLoans.filter((loan) => loan.maxTerm >= loanTerm);

    const loanCards = filteredLoans.map((loan, index) => {
      const monthlyPayment = calculateMonthlyPayment(
        amountCheck,
        loan.rate,
        loanTerm
      );
      const totalPayment = monthlyPayment * loanTerm * 12;
      const totalInterest = totalPayment - amountCheck;
      const processingFees = loan.fees; // Example static processing fee

      return {
        id: index,
        name: loan.name,
        monthlyPayment: formatNumber(monthlyPayment.toFixed(2)),
        interestRate: (loan.rate * 100).toFixed(2), // Converted to percentage
        totalInterest: formatNumber(totalInterest.toFixed(2)),
        totalPayment: formatNumber(totalPayment.toFixed(2)),
        processingFees: formatNumber(processingFees.toFixed(2)),
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras viverra pellentesque aliquet. Vivamus nec enim a enim accumsan auctor. Nulla sit amet tempus eros, sed cursus tellus.",
        image: loan.image,
      };
    });

    setResultsCount(loanCards.length);

    const sortedLoanCards = loanCards.sort((a, b) => {
      console.log(a.totalInterest);
      console.log(b.totalInterest);

      if (a.interestRate === b.interestRate) {
        // Secondary sort by score (descending)
        return a.processingFees - b.processingFees;
      }
      // Primary sort by age (ascending)
      return a.interestRate - b.interestRate;
    });

    SetLoanCards(sortedLoanCards);
  }; */

  return (
    <>
      <LoanPage
        calculateMonthlyPayment={calculateMonthlyPayment}
        formatNumber={formatNumber}
      />
      {/* <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header border-0 pb-1">
              <h4 className="heading">Auto Loans (Result: {resultsCount})</h4>
            </div>
            <div className="card-body pt-1 pb-2">
              <form className="dash-form">
                <div className="row mb-3">
                  <div className="col-lg-4">
                    <div className="form-group mt-2">
                      <label className="form-label">Loan Amount (JMD):</label>
                      <input
                        type="number"
                        className="form-control"
                        value={loanAmount}
                        onChange={handleLoanAmountChange}
                        min="1"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-2">
                      <label className="form-label">Loan Term:</label>
                      <Select
                        options={loanTerms}
                        className="custom-react-select"
                        value={selectedLoanTerm}
                        onChange={handleLoanTermChange}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-2">
                      <label className="form-label">Car Year:</label>
                      <Select
                        options={years}
                        className="custom-react-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {loanCards.map((loan) => (
          <div key={loan.id} className="col-lg-12 col-xl-4 mb-4">
            <div className="card">
              <div class="card-header pb-1 pt-3">
                <h6>
                  <Link to="ecom-product-detail">{loan.name}</Link>
                </h6>
              </div>
              <div className="card-body pb-1">
                <div className="row m-b-30">
                  <div className="col-md-12">
                    <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                      <div className="new-arrivals-img-contnent">
                        <img
                          className="img-fluid"
                          src={loan.image}
                          alt={loan.name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-muted fs-12">
                          Monthly Payment
                        </span>
                        <h2 className="text-primary">${loan.monthlyPayment}</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <span className="text-muted fs-12">Interest Rate:</span>
                        <h2 className="heading fs-20">{loan.interestRate}%</h2>
                      </div>
                      <div className="col-6">
                        <span className="text-muted fs-12">
                          Processing Fees:
                        </span>
                        <h2 className="heading fs-20">
                          ${loan.processingFees}
                        </h2>
                      </div>
                      <div className="col-6">
                        <span className="text-muted fs-12">
                          Total Interest:
                        </span>
                        <h2 className="heading fs-20">${loan.totalInterest}</h2>
                      </div>
                      <div className="col-6">
                        <span className="text-muted fs-12">
                          Total Payments:
                        </span>
                        <h2 className="heading fs-20">${loan.totalPayment}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-end">
                <a
                  class="btn btn-primary p-2 fs-12"
                  href="/react/demo/coin-details"
                >
                  Request Callback <i class="fa fa-caret-right"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
};
export default LoanCalculator;
