import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
const SingleCard = React.lazy(() => import("./SingleCard"));
const CompanyBanner = React.lazy(() => import("../CompanyMenu"));
const convertPrice = (price) => {
  return price * 155;
};
const CompareCreditCards = () => {
  const dispatch = useDispatch();
  const { cardList, cardsLoading } = useSelector((state) => state.cards);
  const [activeGenre, setActiveGenre] = useState(1);
  const { currBank } = useSelector((state) => state.company);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    import("../../../services/CreditCardService")
      .then((cs) => dispatch(cs.getCreditCards(currBank._id)))
      .catch((err) => console.log(err));
  }, [dispatch, currBank._id]);

  useEffect(() => {
    const tempList = [...cardList];
    if (activeGenre === 1) {
      let list = tempList.sort((a, b) => {
        if (
          a.currency.toLowerCase().includes("usd") &&
          b.currency.toLowerCase() === "jmd"
        ) {
          return (
            convertPrice(parseFloat(a.annualFee)) - parseFloat(b.annualFee)
          );
        }
        if (
          b.currency.toLowerCase().includes("usd") &&
          a.currency.toLowerCase().includes("jmd")
        ) {
          return (
            parseFloat(a.annualFee) - convertPrice(parseFloat(b.annualFee))
          );
        }
        return parseFloat(a.annualFee) - parseFloat(b.annualFee);
      });
      return setFiltered(list);
    }

    if (activeGenre === 2) {
      return setFiltered(
        tempList
          .filter((x) => parseFloat(x.interestFee) !== 0)
          .sort((a, b) => parseFloat(a.interestFee) - parseFloat(b.interestFee))
      );
    }
    if (activeGenre === 3) {
      return setFiltered(
        tempList
          .filter(
            (x) =>
              parseFloat(x.minPayPcnt) !== 0 &&
              !x.minPayPcnt.toLowerCase().includes("n/a")
          )
          .sort((a, b) => parseFloat(a.minPayPcnt) - parseFloat(b.minPayPcnt))
      );
    }
  }, [activeGenre, cardList]);
  return (
    <>
      <div className="row">
        <CompanyBanner link={"/credit-cards"} productType={"credit-cards"} />
        {cardsLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !cardList.length ? (
          <h1>No credit cards found.</h1>
        ) : (
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-4">
                <div className="card check-filter">
                  <div className="card-body site-filters clearfix">
                    <ul className="filters d-grid" data-bs-toggle="radio">
                      <li data-filter=".rated" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={() => setActiveGenre(1)}
                          checked={activeGenre === 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Annual Fee
                        </label>
                      </li>
                      <li data-filter=".notrated" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          onChange={() => setActiveGenre(2)}
                          checked={activeGenre === 2}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Interest Rate
                        </label>
                      </li>
                      <li data-filter=".spo" className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          onChange={() => setActiveGenre(3)}
                          checked={activeGenre === 3}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Minimum Payment
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-10">
                <ul
                  //layout
                  id="masonry"
                  className="row"
                  //transition={{ duration: 0.3 }}
                >
                  <AnimatePresence>
                    {filtered.map((item, index) => {
                      return (
                        <motion.li
                          layout
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="col-lg-12 col-md-6 infra rated px-3"
                          key={index}
                          //transition={{ duration: 0.5 }}
                        >
                          <SingleCard item={item} />
                        </motion.li>
                      );
                    })}
                  </AnimatePresence>
                </ul>
                {/* <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                // id="application-tbl1_paginate"
              >
                <Link className="paginate_button previous " to="/credit-cards">
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  {pagination.map((number, i) => (
                    <Link
                      key={i}
                      to="/credit-cards"
                       className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                        } `}
                    >
                      {number}
                    </Link>
                  ))}
                </span>

                <Link className="paginate_button next" to="/credit-cards">
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CompareCreditCards;
