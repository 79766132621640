import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createOption } from "../../../util/createOptions";

//position is the side where the select is displayed. i.e left/right
const ProductSelect = ({ position, product }) => {
  const { cardsToCompare, selectOptions } = useSelector(
    (state) => state.comparer
  );
  const dispatch = useDispatch();

  const onChange = (e, product) => {
    if (product === "auto-loan" || product === "mortgage")
      return import("../../../services/LoanService")
        .then((loanService) =>
          dispatch(loanService.getLoan({ loanId: e.value, position }))
        )
        .catch((error) => console.log(error));
    if (product === "credit-card")
      return import("../../../services/CreditCardService")
        .then((cardService) =>
          dispatch(cardService.getCreditCard({ cardId: e.value, position }))
        )
        .catch((error) => console.log(error));
  };
  return (
    <Select
      value={
        position === "left"
          ? createOption(cardsToCompare[0]) :
          position === "right" ? createOption(cardsToCompare[1])
            : createOption(cardsToCompare[2])
      }
      className="custom-react-select"
      options={selectOptions}
      onChange={(e) => onChange(e, product)}
    />
  );
};
export default ProductSelect;
