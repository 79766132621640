import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { splitQueryString } from "../../../util";
import { setState } from "../../../store/reducers/LoanReducer";
const LoanFilter = React.lazy(() => import("./LoanFilter"));
const LoanCard = React.lazy(() => import("./LoanCard"));
const images = [
  {
    name: "Sagicor",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1731429179/ratehogs/Standardized/SagicorBank_PNG_Standardized_cosycc_cxa5qt.png",
  },
  {
    name: "First Global",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1731428889/ratehogs/Standardized/FirstGlobal_PNG_Standardized_tl6ijj.png",
  },
  {
    name: "First Caribbean",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730198402/ratehogs/Standardized/CIBC_PNG_Standardized_clfrgc.png",
  },
  {
    name: "Scotia",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730199382/ratehogs/Standardized/Scotia_PNG_Standardized_tndshc.png",
  },
  {
    name: "Jamaica Nation",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730197581/ratehogs/Standardized/JN_PNG_Standardized_kuv93i.png",
  },
  {
    name: "National Commercial",
    imageUrl:
      "https://res.cloudinary.com/solutechjm/image/upload/v1730197581/ratehogs/Standardized/NCB_PNG_Standardized_cosycc.png",
  },
];
const getImage = (bankName) => {
  return images.find((image) => {
    return bankName.toLowerCase().includes(image.name.toLowerCase());
  })?.imageUrl;
};
const LoanPage = ({ formatNumber, calculateMonthlyPayment }) => {
  const { cardList, loan } = useSelector((state) => state.loans);
  const { currBank } = useSelector((state) => state.company);
  const [filtered, setFiltered] = useState([]);
  const dispatch = useDispatch();
  const { search } = useLocation();
  useEffect(() => {
    let params = {};
    params = splitQueryString(search);
    if (
      search &&
      parseFloat(params.loanAmount) !== loan.loanAmt &&
      loan.vehYear.value !== params.vehYear
    ) {
      dispatch(
        setState({
          loan: {
            ...loan,
            loanAmt: parseFloat(params.loanAmount),
            vehYear: { label: params.vehYear, value: params.vehYear },
          },
        })
      );
    }
    import("../../../services/LoanService")
      .then((cs) =>
        dispatch(
          cs.getLoans({
            companyId: currBank._id,
            productType: "autoloan",
            params: Object.keys(params) ? params : null,
          })
        )
      )
      .catch((err) => console.log(err));
  }, [dispatch, currBank?._id, search, loan]);

  const generateCards = useCallback(
    (loanList, loanAmt, loanTerm, vehYr) => {
      const amountCheck = Number.isNaN(loanAmt) ? 0 : loanAmt;
      const currentYear = new Date().getFullYear();
      let potentialLoans = [];
      if ((currentYear + 1) - vehYr <= 1)
        potentialLoans = loanList.filter((loan) =>
          loan.productName.toLowerCase().includes("new")
        );
      else
        potentialLoans = loanList.filter(
          (loan) => !loan.productName.toLowerCase().includes("new")
        );

      potentialLoans = potentialLoans
        .map((loan) => {
          let retLoan = {};
          if (isNaN(parseFloat(loan.maxRpyPeriod))) {
            retLoan = {
              ...loan,
              maxRpyPeriod: loan.maxRpyPeriod.split(" ")[2],
            };
          } else if (loan.maxRpyPeriod.includes("-")) {
            retLoan = {
              ...loan,
              maxRpyPeriod: loan.maxRpyPeriod.split(" ")[0],
            };
          } else
            retLoan = { ...loan, maxRpyPeriod: parseFloat(loan.maxRpyPeriod) };
          return retLoan;
        })
        .filter((loan) => parseFloat(loan.maxRpyPeriod) >= parseFloat(loanTerm))
        .map((loan) => {
          const monthlyPayment = calculateMonthlyPayment(
            amountCheck,
            parseFloat(loan.intRate) / 100,
            parseFloat(loanTerm)
          );

          const totalPayment = monthlyPayment * loanTerm * 12;
          const totalInterest = totalPayment - amountCheck;
          return {
            ...loan,
            imageUrl: getImage(loan.companyId.name),
            monthlyPayment: formatNumber(monthlyPayment.toFixed(2)),
            totalInterest: formatNumber(totalInterest.toFixed(2)),
            totalPayment: formatNumber(totalPayment.toFixed(2)),
            processingFee: formatNumber(
              (loan.processingFeeRate * loanAmt).toFixed(2)
            ),
          };
        })
        .filter((x) => !isNaN(parseFloat(x.monthlyPayment)))
        .sort((a, b) => {
          if (a.intRate === b.intRate) {
            // Secondary sort by score (descending)
            return a.processingFee - b.processingFee;
          }
          // Primary sort by age (ascending)
          return parseFloat(a.intRate) - parseFloat(b.intRate);
        });
      setFiltered(potentialLoans);
    },
    [calculateMonthlyPayment, formatNumber]
  );

  useEffect(() => {
    const tempList = [...cardList];
    generateCards(
      tempList,
      loan?.loanAmt,
      loan?.loanTerm.value,
      loan?.vehYear.value
    );
  }, [cardList, generateCards, loan]);


  return (
    <>
      <LoanFilter resultsCount={filtered?.length} />
      <div className="row">
        {filtered.map((loan) => (
          <LoanCard key={loan._id} loan={loan} />
        ))}
      </div>
    </>
  );
};

export default LoanPage;
